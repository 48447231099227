.school-reviews
  padding: 120px 0 103px

.school-reviews__title
  width: 780px
  margin: 0 auto
  font-size: 40px
  line-height: 115%
  text-align: center
  
.school-reviews__slider-wrapper
  position: relative
  display: flex
  width: 100%
  
.school-reviews__slider-list
  display: flex
  margin-top: 13px
  
.school-review
  display: flex
  width: 100%
  
.school-review__item
  display: flex
  flex-direction: column
  min-width: 440px
  padding: 26px 28px 30px
  background: $white
  box-shadow: 0px 0px 40px 0px rgba(0, 0, 0, 0.05)
  border: 1px solid #EDEDED
  border-radius: 24.667px
  
.school-review__author
  margin-bottom: 30px

.author-review
  display: flex
  align-items: center
  
.author-review__pic
  width: 56px
  height: 56px
  
.author-review__name
  margin-left: 13px
  font-size: 18px
  font-weight: 600
  line-height: normal

  span
    display: block
    font-weight: 400
  
.school-review__text
  font-size: 14px
  line-height: 140%
  margin-bottom: 10px
  user-select: none

  &:last-of-type
    margin-bottom: 0

.school-review__about-lesson
  margin-top: auto

.lesson
  padding: 20px
  background-color: #FAF8F5
  border-radius: 20px
  user-select: none
  
.lesson__params-list
  display: flex
  width: max-content
  justify-content: space-between
  gap: 5px

.lesson__params-elem
  position: relative
  display: flex
  align-items: center
  height: 19px
  padding-left: 18px
  font-size: 10px
  color: #A4A4A4

  &::before
    content: ''
    position: absolute
    top: 50%
    transform: translateY(-50%)
    left: 0
    width: 15px
    height: 15px
  
  &.icon-calendar
    &::before
      background: url('../img/icons/icon-calendar.svg') no-repeat center
  
  &.icon-certificate
    &::before
      background: url('../img/icons/icon-certificate.svg') no-repeat center
  
  &.icon-clock
    &::before
      background: url('../img/icons/icon-clock.svg') no-repeat center
  
  &.icon-course
    &::before
      background: url('../img/icons/icon-course.svg') no-repeat center
      
.lesson__title
  margin-top: 10px
  font-size: 20px
  font-weight: 600
  line-height: 24px
  
.lesson__link
  margin-top: 10px
  font-size: 16px
  font-weight: 700
  color: $basic-orange
  text-decoration: underline
  text-decoration-thickness: 1px
  text-underline-offset: 4px

.school-review__date
  margin-top: 10px
  font-size: 14px
  opacity: 0.45

.school-reviews__slider-controls.slider-btn
  position: absolute
  top: 50%
  left: -85px
  transform: translateY(-50%)
  display: flex
  justify-content: space-between
  width: 130%
  height: 62px !important
  margin: 0 auto

.school-review__text-wrapper
  overflow: hidden
  transition: all 0.3s

.school-review__text-wrapper + a
  font-size: 15px
  color: #ff8a0d

.school-reviews__more
  margin: 50px auto 0
  width: 380px
  height: 50px