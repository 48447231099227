/* Fonts */
@font-face {
  font-family: "ProximaNova";
  font-style: normal;
  font-weight: 300;
  src: local("ProximaNova-Light"), local("Proxima Nova Light"), url("../fonts/ProximaNova-Light.woff") format("woff");
}
@font-face {
  font-family: "ProximaNova";
  font-style: normal;
  font-weight: 400;
  src: local("ProximaNova-Regular"), local("Proxima Nova Regular"), url("../fonts/ProximaNova-Regular.woff") format("woff");
}
@font-face {
  font-family: "ProximaNova";
  font-style: normal;
  font-weight: 600;
  src: local("ProximaNova-Semibold"), local("Proxima Nova Semibold"), url("../fonts/ProximaNova-Semibold.woff") format("woff");
}
@font-face {
  font-family: "ProximaNova";
  font-style: normal;
  font-weight: 700;
  src: local("ProximaNova-Bold"), local("Proxima Nova Bold"), url("../fonts/ProximaNova-Bold.woff") format("woff");
}
/*  */
*,
*::before,
*::after {
  box-sizing: inherit;
}

html {
  min-width: 1200px;
  min-height: 100%;
  box-sizing: border-box;
  background-color: white;
  scroll-behavior: smooth;
}

body {
  position: relative;
  display: grid;
  grid-template-rows: min-content 1fr min-content;
  height: 100%;
  margin: 0;
  font-family: "ProximaNova", "Arial", sans-serif;
  font-size: 24px;
  font-weight: 400;
  line-height: 32px;
  color: #000000;
}

main {
  overflow: hidden;
}

h1, h2, h3, h4 {
  margin: 0;
}

ul {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

p {
  margin: 0;
}

a {
  text-decoration: none;
}

img {
  max-width: 100%;
  height: auto;
}

button {
  padding: 0;
  font-family: inherit;
  border: none;
  background-color: transparent;
}

.container {
  width: 1200px;
  padding: 0 5px;
  margin: 0 auto;
}

.visually-hidden:not(:focus):not(:active),
input[type=checkbox].visually-hidden,
input[type=radio].visually-hidden {
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  border: 0;
  padding: 0;
  white-space: nowrap;
  clip-path: inset(100%);
  clip: rect(0 0 0 0);
  overflow: hidden;
}

.button-basic {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 21px;
  color: #ffffff;
  border-radius: 6px;
  transition: 0.2s;
  cursor: pointer;
  font-family: inherit;
}
.button-basic--yellow {
  color: #000;
  background-color: #F6C443;
}
.button-basic--yellow:hover {
  background-color: #ffd76e;
}
.button-basic--blue {
  color: #ffffff;
  background-color: #2071EB;
}
.button-basic--blue:hover {
  background-color: #3280f7;
}
.button-basic--orange {
  border-radius: 10px;
  background-color: #FF8A0D;
}
.button-basic--orange:hover {
  background-color: #FF7900;
}

.phone {
  position: relative;
  display: block;
  color: #5D5A59;
  font-size: 12px;
  line-height: normal;
}

.slider-btn__prev,
.slider-btn__next {
  width: 62px;
  height: 100%;
  cursor: pointer;
  z-index: 99;
  filter: drop-shadow(0px 0px 10px rgba(0, 0, 0, 0.25));
}
.slider-btn__prev.swiper-button-disabled,
.slider-btn__next.swiper-button-disabled {
  opacity: 0.5;
}

.slider-btn__prev {
  background: url("../img/icons/icon-arrow-slider-right.svg") no-repeat center;
  background-size: contain;
}

.slider-btn__next {
  background: url("../img/icons/icon-arrow-slider-left.svg") no-repeat center;
  background-size: contain;
}

.header-top {
  display: flex;
  align-items: center;
  height: 34px;
  padding-left: 18px;
  line-height: 0;
  border-bottom: 1px solid #E5E5E5;
}

.header__logo {
  padding-left: 18px;
}

.header__access-link {
  margin-left: auto;
  font-size: 12px;
  line-height: normal;
  color: #5D5A59;
}

.header__phone {
  margin: 0 30px;
  padding-left: 17px;
}
.header__phone::before {
  content: "";
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 0;
  width: 10px;
  height: 10px;
  background: url("../img/icons/icon-phone.svg") no-repeat center;
}

.header__signin {
  display: inline-block;
  align-self: flex-end;
}

.header-bottom {
  padding: 21px 0 27px;
}

.header-bottom__content {
  display: flex;
  align-items: center;
  height: 37px;
  line-height: 0;
}

.header-bottom__nav {
  margin-left: auto;
}

.navigation__list {
  width: 780px;
  display: flex;
  justify-content: space-between;
}

.navigation__link {
  font-size: 14px;
  font-weight: 600;
  line-height: 25px;
  color: #000000;
}

.header-bottom__phone-wrapper {
  position: relative;
  margin-left: 57px;
  padding-left: 38px;
  font-size: 14px;
  line-height: normal;
}
.header-bottom__phone-wrapper span {
  display: block;
  font-size: 12px;
}
.header-bottom__phone-wrapper::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 25px;
  height: 25px;
  background: url("../img/icons/icon-whatsapp.svg") no-repeat center;
}

.header-bottom__phone {
  font-size: 14px;
  font-weight: 600;
  color: #000000;
}

.promo {
  position: relative;
  z-index: 0;
}

.promo__content {
  position: relative;
  padding: 70px 0 56px 80px;
  background-color: #FAF8F5;
  border-radius: 20px;
}

.promo__title {
  width: 490px;
  font-size: 30px;
  font-weight: 600;
  line-height: 35px;
}

.promo__desc {
  width: 430px;
  margin-top: 26px;
  font-size: 16px;
  line-height: 22px;
}

.promo__link {
  width: 402px;
  height: 60px;
  margin-top: 26px;
  font-size: 20px;
  font-weight: 600;
  border-radius: 12px;
}

.promo__pic-wrapper {
  position: absolute;
  top: 32px;
  right: 37px;
  width: 610px;
  height: 360px;
  object-fit: contain;
}

.promo__content-pic {
  position: absolute;
  height: 100%;
  object-fit: contain;
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
}

.tutortop-rating,
.vtb-cashback {
  position: absolute;
  top: 46px;
  right: 10px;
  width: 106px;
  height: 106px;
  z-index: 2;
}

.vtb-cashback {
  top: 160px;
  right: 3px;
  width: 112px;
  height: 115px;
}

.tutortop-rating__pic,
.vtb-cashback__pic {
  width: 100%;
  height: 100%;
}

.attestation {
  margin-top: 52px;
}

.attestation__content {
  position: relative;
  padding: 31px 0 33px 80px;
  background-color: #FAF8F5;
  border-radius: 20px;
}

.attestation__bage {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 16px;
  width: max-content;
  padding: 0 7px 0 24px;
  font-size: 10px;
  font-weight: 600;
  color: white;
  background-color: #F6BA2F;
  border-radius: 4px;
}
.attestation__bage::before {
  content: "";
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 7px;
  width: 13px;
  height: 13px;
  background: url("../img/icons/icon-attestation.svg") no-repeat center;
}

.attestation__title {
  width: 415px;
  margin-top: 20px;
  font-size: 30px;
  font-weight: 600;
  line-height: 34px;
}

.attestation__lnik {
  width: 300px;
  height: 51px;
  margin-top: 30px;
  font-size: 16px;
  font-weight: 600;
}

.attestation__pic {
  position: absolute;
  top: 23px;
  right: 119px;
  width: 393px;
  height: 206px;
}

.education {
  padding-top: 82px;
}

.education__title {
  width: 850px;
  margin: 0 auto;
  font-size: 30px;
  font-weight: 600;
  line-height: 34px;
  text-align: center;
}

.education__directions-choice {
  margin-top: 50px;
}

.direction {
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
}

.direction__elem {
  display: inline-block;
  width: max-content;
}

.direction__btn {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 32px;
  padding: 0 25px;
  font-size: 16px;
  font-weight: 600;
  color: #FF8A0D;
  border: 1px solid #FF8A0D;
  border-radius: 6px;
  cursor: pointer;
}

.direction-selected {
  background-color: #FF8A0D;
  color: #ffffff;
}

.education__training-list {
  display: grid;
  grid-template-columns: repeat(3, 380px);
  grid-gap: 20px;
  margin-top: 40px;
}

.training {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 20px;
  background-color: #FAF8F5;
  border-radius: 20px;
  user-select: none;
  display: none;
}

.training__params-list {
  display: flex;
  width: max-content;
  justify-content: space-between;
  gap: 5px;
}

.training--show {
  display: flex;
}

.training__params-elem {
  position: relative;
  display: flex;
  align-items: center;
  height: 19px;
  padding-left: 18px;
  font-size: 10px;
  color: #A4A4A4;
}
.training__params-elem::before {
  content: "";
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 0;
  width: 15px;
  height: 15px;
}
.training__params-elem:first-of-type::before {
  background: url("../img/icons/icon-calendar.svg") no-repeat center;
}
.training__params-elem:nth-child(2)::before {
  background: url("../img/icons/icon-certificate.svg") no-repeat center;
}
.training__params-elem:nth-child(3)::before {
  background: url("../img/icons/icon-clock.svg") no-repeat center;
}
.training__params-elem:nth-child(4)::before {
  background: url("../img/icons/icon-course.svg") no-repeat center;
}

.training__title {
  margin-top: 22px;
  font-size: 20px;
  font-weight: 600;
  line-height: 24px;
}

.training__desc {
  font-size: 14px;
  line-height: 18px;
  margin-top: 20px;
}

.training__subject-btn {
  margin-top: 15px;
  margin-bottom: 20px;
  font-size: 14px;
  color: #A4A4A4;
  text-decoration: underline;
  text-decoration-thickness: 1px;
  text-underline-offset: 4px;
  cursor: pointer;
  transition: 0.3s;
}
.training__subject-btn:hover {
  color: #cabfbf;
}

.training__subject-list {
  margin-top: 15px;
  display: none;
}

.training__subject {
  position: relative;
  padding-left: 22px;
  font-size: 16px;
  line-height: 130%;
  color: #8892B2;
}
.training__subject::before {
  content: "";
  position: absolute;
  top: 3px;
  left: 0;
  width: 12px;
  height: 12px;
  background: url("../img/icons/icon-bullit.svg") no-repeat center;
  background-size: cover;
}

.training__prices {
  margin-top: auto;
  order: 1;
}

.training__prices-new {
  font-size: 20px;
  font-weight: 600;
}

.training__prices-old {
  margin-left: 15px;
  font-size: 14px;
  color: #A4A4A4;
  text-decoration: line-through;
}

.training__pay-wrapper {
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 100%;
  margin-top: 20px;
  order: 1;
}

.training__pay-card,
.training__pay-bill {
  display: flex;
  height: 41px;
  font-size: 14px;
  font-weight: 600;
  border-radius: 5px;
}

.training__pay-bill {
  color: #FF8A0D;
  border: 1px solid #FF8A0D;
}

.support {
  margin-top: 144px;
}

.support__wrapper {
  position: relative;
  padding: 84px 0 42px 91px;
  color: #ffffff;
  background-color: #27A6E5;
  border-radius: 20px;
  z-index: 0;
}
.support__wrapper::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 280px;
  background: url("../img/icons/icon-tg-bg.svg") no-repeat center;
  z-index: -1;
}

.support__title {
  width: 550px;
  font-size: 30px;
  line-height: 35px;
  font-weight: 600;
}

.support__desc {
  width: 550px;
  margin-top: 20px;
  font-size: 16px;
  line-height: 22px;
}

.support__link {
  width: 340px;
  height: 50px;
  margin: 30px 0 0 0;
  font-size: 16px;
  background-color: #ffffff;
  color: #000000;
}

.support__pic {
  position: absolute;
  right: 0;
  bottom: 0;
  width: 480px;
}

.study {
  padding-top: 100px;
}

.study__title {
  font-size: 30px;
  font-weight: 600;
  text-align: center;
}

.study__wrapper {
  margin: 0 -16px;
}

.study__options {
  margin-top: 30px;
}

.options-table {
  border-spacing: 16px 0;
}
.options-table tr:not(:first-of-type) td {
  position: relative;
}
.options-table tr:first-of-type td {
  padding: 10px 0;
}
.options-table tr:first-of-type td span {
  display: flex;
  justify-content: center;
  align-items: center;
  width: auto;
  height: 70px;
  margin: 0 10px;
  padding: 15px 5px;
  background-color: #FF8A0D;
  border-radius: 5px;
  color: #ffffff;
}
.options-table tr:first-of-type td::before {
  display: none;
}
.options-table tr:first-of-type td:not(:first-of-type) {
  border-radius: 10px 10px 0 0;
}
.options-table tr:last-of-type td::before {
  display: none;
}
.options-table td {
  width: 173px;
  padding: 25px 0;
}
.options-table td::before {
  content: "";
  position: absolute;
  bottom: 0;
  height: 1px;
  background-color: #E2E2E2;
  width: 100%;
}
.options-table td:not(:first-of-type) {
  padding-left: 10px;
  padding-right: 10px;
}
.options-table td:not(:first-of-type)::before {
  left: 50%;
  transform: translateX(-50%);
  width: 85%;
}
.options-table td:first-of-type {
  width: 246px;
}
.options-table td:nth-child(2) {
  width: 160px;
}
.options-table td:not(:first-of-type) {
  text-align: center;
  background-color: #FAF8F5;
}

.options-table__data {
  font-size: 16px;
  font-weight: 600;
  line-height: 20px;
}

.options-table__link {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 34px;
  margin-top: 10px;
  padding: 0 20px;
  font-size: 10px;
  line-height: 12px;
  border-radius: 5px;
  color: #ffffff;
}

.study__link {
  width: 300px;
  height: 51px;
  margin: 50px auto 0;
}

.about {
  padding-top: 133px;
}

.about__wrapper {
  position: relative;
  padding: 60px 0 59px 50px;
  background-color: #FAF8F5;
  border-radius: 20px;
}

.about__title {
  font-size: 40px;
  line-height: 35px;
  font-weight: 600;
}

.about__desc {
  width: 570px;
  margin-top: 37px;
  font-size: 18px;
  line-height: 25px;
}

.about__link {
  width: 340px;
  height: 50px;
  margin: 30px 0 0 0;
  font-size: 16px;
  background-color: #ffffff;
  color: #000000;
}

/*  */
.diplom {
  position: absolute;
  right: 80px;
  top: -90px;
}

.diplom-back {
  position: relative;
  perspective: 3000px;
  perspective-origin: 50% 70%;
}

.diplom-back img {
  max-width: 375px;
}

.diplom-back__prof-pic {
  position: absolute;
  right: 60px;
  bottom: 150px;
  display: none;
}

.diplom-back__prof-pic img {
  max-width: 150px;
}

.diplom-back__prof-pic.prof-pic__anim {
  animation: 1.4s linear picdow;
  display: block;
}

@keyframes picdow {
  0% {
    opacity: 0;
    transform: translateY(-2000px);
  }
  60% {
    opacity: 1;
    transform: translateY(30px);
  }
  80% {
    transform: translateY(-10px);
  }
  100% {
    transform: translateY(0);
  }
}
.diplom-back__data {
  position: absolute;
  display: inline-block;
  right: 50px;
  top: 120px;
  z-index: 4;
  font-size: 12px;
  max-width: 150px;
  font-weight: bold;
}

.diplom-back__data span {
  display: block;
}

.diplom-back__name {
  margin-bottom: 15px;
}

.diplom-back__program {
  margin-bottom: 70px;
  font-size: 12px;
  line-height: 1;
}

.diplom-back__course,
.diplom-back__date {
  font-size: 12px;
  line-height: 1;
}

.diplom-front {
  position: absolute;
  top: 0;
  left: 0;
  transform-origin: left center;
  transform: rotateY(0deg);
  transition: 0.5s;
  z-index: 5;
}

.diplom-front:hover {
  transform: rotateY(-25deg);
  transition: 0.5s;
  cursor: pointer;
}

.diplom-front.animate {
  transform: rotateY(-84deg);
}

.questions {
  margin-top: 94px;
  background-color: #ffffff;
}

.questions__title {
  margin: 0;
  font-size: 40px;
  font-weight: 600;
  text-align: center;
}

.questions__lists {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 20px;
  margin-top: 40px;
}

.questions__lists-elem:first-of-type .question__item:first-of-type {
  background-color: #FAF8F5;
}

.questions__list {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.question__item {
  display: flex;
  flex-direction: column;
  width: 580px;
  padding: 40px;
  border: 1px solid #D8D8D8;
  border-radius: 10px;
}

.question__button {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 0;
  font-size: 28px;
  font-weight: 600;
  text-align: left;
  color: #2D3039;
  border: 0;
  background-color: transparent;
  cursor: pointer;
  user-select: none;
}
.question__button:before {
  content: "";
  position: absolute;
  top: 16px;
  transform: translateY(-50%);
  right: 0;
  width: 25px;
  height: 25px;
  background: url("../img/icons/icon-plus.svg") no-repeat center;
  background-size: contain;
  transition: 0.1s;
}
.question__button--active {
  padding-bottom: 0;
}
.question__button--active + .question__answer {
  display: block;
}
.question__button--active:before {
  background: url("../img/icons/icon-minus.svg") no-repeat center;
}

.question__answer {
  width: 100%;
  margin-top: 15px;
  font-size: 20px;
  line-height: 30px;
  animation: transition 0.2s linear;
  display: none;
  user-select: none;
}

@keyframes transition {
  0% {
    opacity: 0;
    transform: translateY(-20px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}
.school-reviews {
  padding: 120px 0 103px;
}

.school-reviews__title {
  width: 780px;
  margin: 0 auto;
  font-size: 40px;
  line-height: 115%;
  text-align: center;
}

.school-reviews__slider-wrapper {
  position: relative;
  display: flex;
  width: 100%;
}

.school-reviews__slider-list {
  display: flex;
  margin-top: 13px;
}

.school-review {
  display: flex;
  width: 100%;
}

.school-review__item {
  display: flex;
  flex-direction: column;
  min-width: 440px;
  padding: 26px 28px 30px;
  background: #ffffff;
  box-shadow: 0px 0px 40px 0px rgba(0, 0, 0, 0.05);
  border: 1px solid #EDEDED;
  border-radius: 24.667px;
}

.school-review__author {
  margin-bottom: 30px;
}

.author-review {
  display: flex;
  align-items: center;
}

.author-review__pic {
  width: 56px;
  height: 56px;
}

.author-review__name {
  margin-left: 13px;
  font-size: 18px;
  font-weight: 600;
  line-height: normal;
}
.author-review__name span {
  display: block;
  font-weight: 400;
}

.school-review__text {
  font-size: 14px;
  line-height: 140%;
  margin-bottom: 10px;
  user-select: none;
}
.school-review__text:last-of-type {
  margin-bottom: 0;
}

.school-review__about-lesson {
  margin-top: auto;
}

.lesson {
  padding: 20px;
  background-color: #FAF8F5;
  border-radius: 20px;
  user-select: none;
}

.lesson__params-list {
  display: flex;
  width: max-content;
  justify-content: space-between;
  gap: 5px;
}

.lesson__params-elem {
  position: relative;
  display: flex;
  align-items: center;
  height: 19px;
  padding-left: 18px;
  font-size: 10px;
  color: #A4A4A4;
}
.lesson__params-elem::before {
  content: "";
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 0;
  width: 15px;
  height: 15px;
}
.lesson__params-elem.icon-calendar::before {
  background: url("../img/icons/icon-calendar.svg") no-repeat center;
}
.lesson__params-elem.icon-certificate::before {
  background: url("../img/icons/icon-certificate.svg") no-repeat center;
}
.lesson__params-elem.icon-clock::before {
  background: url("../img/icons/icon-clock.svg") no-repeat center;
}
.lesson__params-elem.icon-course::before {
  background: url("../img/icons/icon-course.svg") no-repeat center;
}

.lesson__title {
  margin-top: 10px;
  font-size: 20px;
  font-weight: 600;
  line-height: 24px;
}

.lesson__link {
  margin-top: 10px;
  font-size: 16px;
  font-weight: 700;
  color: #FF8A0D;
  text-decoration: underline;
  text-decoration-thickness: 1px;
  text-underline-offset: 4px;
}

.school-review__date {
  margin-top: 10px;
  font-size: 14px;
  opacity: 0.45;
}

.school-reviews__slider-controls.slider-btn {
  position: absolute;
  top: 50%;
  left: -85px;
  transform: translateY(-50%);
  display: flex;
  justify-content: space-between;
  width: 130%;
  height: 62px !important;
  margin: 0 auto;
}

.school-review__text-wrapper {
  overflow: hidden;
  transition: all 0.3s;
}

.school-review__text-wrapper + a {
  font-size: 15px;
  color: #ff8a0d;
}

.school-reviews__more {
  margin: 50px auto 0;
  width: 380px;
  height: 50px;
}

.footer {
  font-family: "ProximaNova", "Arial", sans-serif;
  color: #ffffff;
  background-color: #1F2229;
}

.footer__wrapper {
  display: flex;
  padding: 46px 0 60px 0;
  line-height: 16px;
}

.footer__copyright {
  margin: 0;
  font-size: 13px;
}

.footer__copyright-link {
  display: flex;
  flex-direction: column;
  display: inline;
  color: #ffffff;
  text-decoration: underline;
}

.footer__policy {
  display: block;
  margin-top: 20px;
  text-decoration: underline;
  color: #ffffff;
}

.footer__callback {
  margin: 0;
  margin-left: 167px;
  font-size: 13px;
}

.footer__phone {
  display: block;
  margin-top: 10px;
  font-size: 18px;
  font-weight: 700;
  line-height: 22px;
  color: #ffffff;
}

.footer__logo-link {
  display: flex;
  margin-left: auto;
}

.footer__logo {
  width: 175px;
  height: 25px;
}