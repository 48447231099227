
$white: #ffffff
$black: #000000
$basic-blue: #2071EB
$basic-gray: #565656
$basic-yellow: #F6C443
$basic-orange: #FF8A0D

*,
*::before,
*::after
  box-sizing: inherit

html
  min-width: 1200px
  min-height: 100%
  box-sizing: border-box
  background-color: white
  scroll-behavior: smooth

body
  position: relative
  display: grid
  grid-template-rows: min-content 1fr min-content
  height: 100%
  margin: 0
  font-family: "ProximaNova", "Arial", sans-serif
  font-size: 24px
  font-weight: 400
  line-height: 32px
  color: $black

main
  overflow: hidden

h1,h2,h3,h4
  margin: 0

ul
  margin: 0
  padding: 0
  list-style-type: none

p
  margin: 0

a
  text-decoration: none

img
  max-width: 100%
  height: auto

button
  padding: 0
  font-family: inherit
  border: none
  background-color: transparent

.container
  width: 1200px
  padding: 0 5px
  margin: 0 auto

.visually-hidden:not(:focus):not(:active),
input[type="checkbox"].visually-hidden,
input[type="radio"].visually-hidden
  position: absolute
  width: 1px
  height: 1px
  margin: -1px
  border: 0
  padding: 0
  white-space: nowrap
  clip-path: inset(100%)
  clip: rect(0 0 0 0)
  overflow: hidden

.button-basic
  display: flex
  justify-content: center
  align-items: center
  font-size: 21px
  color: $white
  border-radius: 6px
  transition: 0.2s
  cursor: pointer
  font-family: inherit

  // &--red
  //   background-color: $basic-red

  //   &:hover
  //     background-color:#fe3c43

  &--yellow
    color: #000
    background-color: $basic-yellow

    &:hover
      background-color:#ffd76e

  &--blue
    color: $white
    background-color: $basic-blue

    &:hover
      background-color:#3280f7

  &--orange
    border-radius: 10px
    background-color: $basic-orange

    &:hover
      background-color:#FF7900

.phone
  position: relative
  display: block
  color: #5D5A59
  font-size: 12px
  line-height: normal

.slider-btn__prev,
.slider-btn__next
  width: 62px
  height: 100%
  cursor: pointer
  z-index: 99
  filter: drop-shadow(0px 0px 10px rgba(0, 0, 0, 0.25))

  &.swiper-button-disabled
    opacity: 0.5

.slider-btn__prev
  background: url('../img/icons/icon-arrow-slider-right.svg') no-repeat center
  background-size: contain

.slider-btn__next
  background: url('../img/icons/icon-arrow-slider-left.svg') no-repeat center
  background-size: contain